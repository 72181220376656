import React, { useState } from "react";
import ConfigForm from "FormModal/ConfigForm";

function ConfigMenu(props) {

  const [isFormOpen, setIsFormOpen] = useState(false);
  const handleFormClose = () => {
    setIsFormOpen(false);
  };
  const handleFormSubmit = (formData) => {
    // console.log("Form submitted:", formData);
  };

  return (
    <div className="relative">
      <ConfigForm
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={false}
      />
    </div>
  );
}

export default ConfigMenu;
