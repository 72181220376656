import React from 'react';
import DOMPurify from 'dompurify';

const SanitizedContent = ({ htmlContent }) => {
  const createSanitizedMarkup = () => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <div
      className="prose max-w-none"
      dangerouslySetInnerHTML={createSanitizedMarkup()}
    />
  );
};

export default SanitizedContent;