import React, { useState } from "react";
import { BsEyeFill, BsXCircleFill } from "react-icons/bs";
import { useGlobalContext } from "context/globalContext";
import { ENDPOINT } from "constants/environment";
import Deactivate from "../DeleteModal/Deactivate";
import ConfigForm from "../../FormModal/ConfigForm";
import axios from "axios";
import toast from "react-hot-toast";

const Actions = (props) => {
  const { setTableData, value, isActive } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setIterinaryFormData } = useGlobalContext();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleDeactivate = async (id) => {
    if (!isActive) {
      toast("Already Deactivated");
      setIsModalOpen(false);
      return;
    }

    // console.log("Deactivating ID:", id);
    try {
      const response = await axios.put(
        `${ENDPOINT}/businessSubscription/${id}`
      );

      if (response.status === 200) {
        setTableData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, isActive: false } : item
          )
        );
        toast.success("Deactivated");
      } else {
        // console.error("Error deactivating subscription: ", response.statusText);
        toast.error("Error deactivating");
      }
    } catch (error) {
      // console.error("Error deactivating subscription:", error.message || error);
      toast.error("Failed to deactivate");
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setIsFormOpen(true);

    setIterinaryFormData({
      ...props.cell.row.original,
    });
    // console.log(props, "props");
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  return (
    <div className="ml-5 flex gap-4">
      <BsEyeFill
        className="cursor-pointer fill-green-700"
        onClick={handleFormOpen}
      />
      <BsXCircleFill
        className="cursor-pointer fill-amber-700"
        onClick={handleDeleteClick}
      />
      <Deactivate
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={() => handleDeactivate(value)}
      />
      <ConfigForm isOpen={isFormOpen} onClose={handleFormClose} />
    </div>
  );
};

export default Actions;
