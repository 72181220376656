import React from "react";
import Modal from "react-modal";

const Deactivate = ({ isOpen, onRequestClose, onDelete }) => {
  return (

    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Confirmation"
      ariaHideApp={false}
      className="mx-auto my-40 flex h-1/3 w-1/3 flex-col items-center justify-center rounded-lg bg-white p-6 shadow-lg"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2 className="mb-4 text-lg font-semibold text-center">
        Are you sure you want to Deactivate?
      </h2>

      <div className="flex space-x-4">
        <button
          onClick={onDelete}
          className="rounded bg-red-500 py-2 px-4 text-white hover:bg-red-700  grid sm"
        >
          Deactivate
        </button>
        <button
          onClick={onRequestClose}
          className="rounded bg-gray-500 py-2 px-4 text-white hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default Deactivate;
