import React, { useEffect, useState } from "react";
import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import { format } from "date-fns";
import { useGlobalContext } from "context/globalContext";
import SanitizedContent from "../../../../FormModal/DomPurify";

const NO_DATA_GIF_URL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5rAKr33qbnSMAReEtKgpZ3wWaoYqLa-PE-OJk6CxA8ZuKx1ah7WYpJ_HQ9ab5MYe0DHo&usqp=CAU";
  
const SubscriptionPlan = () => {
  const { getSystemPromptData, tableData } = useGlobalContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getSystemPromptData();
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full">
      <div className="relative top-0 left-0 ">
        <CardMenu getSystemPromptData={getSystemPromptData} />
      </div>

      <div className="mt-4 flex flex-wrap justify-center gap-4">
        {loading ? (
          <div className="mb-4 flex items-center justify-center">
            <svg
              className="mr-3 h-5 w-5 animate-spin text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.556a4.002 4.002 0 00-4 4H6.002zM20 12c0-3.042-1.135-5.82-3-7.938L16 4.472A8.001 8.001 0 0120 12zm-8 7.938A8.001 8.001 0 014.472 16h3.556a4.002 4.002 0 004 4v3.556zm8-7.938a8.001 8.001 0 01-4.472 7.938v-3.556a4.002 4.002 0 00-4-4h3.556z"
              />
            </svg>
            <p>Loading</p>
          </div>
        ) : tableData.length === 0 ? (
          <div className="mb-4 flex flex-col items-center">
            <img
              src={NO_DATA_GIF_URL}
              alt="No Data Available"
              className="h-40 w-40"
            />
            {/* <p>Looks like there's no subscription plan added!</p> */}
          </div>
        ) : (
          tableData.map((dataItem) => (
            <Card
              key={dataItem.id}
              className="rounded-lg bg-white p-4 shadow-lg md:w-1/3 lg:w-1/4"
            >
              <section className="flex justify-between">
                <div className="mb-2">
                  <h3 className="text-xl font-bold">{dataItem.planName}</h3>
                </div>
              </section>

              <div className="mb-4">
                <p>{dataItem.planDescription}</p>
              </div>
              <div className="mb-4">
                <h4 className="font-semibold">Plan features:</h4>
                <SanitizedContent htmlContent={dataItem.planFeatures} />
              </div>
              <div className="mb-4">
                <h4 className="font-semibold">Module permissions:</h4>
                <p>
                  {dataItem.planModulepermission[0]?.user[0]?.feature1
                    ? "Feature 1"
                    : "Feature 2"}
                </p>
              </div>
              <div className="mb-4 flex items-center justify-between">
                <h4 className="font-semibold">Cost</h4>
                <div className="flex">
                  <span>₹</span> <p>{dataItem.planCost}</p>
                  <span className="mt-2 text-xs">/yr</span>
                </div>
              </div>
              <div className="mb-4 flex items-center justify-between">
                <h4 className="font-semibold">Status</h4>
                <div className="flex items-center">
                  {dataItem.is_active ? (
                    <>
                      <MdCheckCircle className="text-green-500" />
                      <span className="ml-2">Active</span>
                    </>
                  ) : (
                    <>
                      <MdOutlineError className="text-red-500" />
                      <span className="ml-2">In-Active</span>
                    </>
                  )}
                </div>
              </div>
              <div className="mb-4 items-center">
                <h4 className="font-semibold">Created At:</h4>
                <p>
                  {format(new Date(dataItem.createdAt), "yyyy-MM-dd HH:mm:ss")}
                </p>
              </div>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default SubscriptionPlan;




// Table format UI

// import React, { useEffect, useMemo, useState } from "react";
// import CardMenu from "components/card/CardMenu";
// import Card from "components/card";
// import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
// import { MdCheckCircle, MdOutlineError } from "react-icons/md";
// import { format } from "date-fns";
// import { useGlobalContext } from "context/globalContext";
// import SanitizedContent from "../../../../FormModal/DomPurify";

// const SubscriptionPlan = () => {
//   const { getSystemPromptData, tableData } = useGlobalContext();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       await getSystemPromptData();
//       setLoading(false);
//     };
//     fetchData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const columns = useMemo(
//     () => [
//       {
//         Header: "Plan Name",
//         accessor: "planName",
//         Cell: ({ value }) => <span className="ml-4">{value}</span>,
//       },
//       {
//         Header: "Plan Description",
//         accessor: "planDescription",
//       },
//       {
//         Header: "Plan Features",
//         accessor: "planFeatures",
//         Cell: ({ value }) => <SanitizedContent htmlContent={value} />,
//       },
//       {
//         Header: "Plan Module Permission",
//         accessor: "planModulepermission",
//         Cell: ({ value }) => {
//           const feature1 = value[0]?.user[0]?.feature1;
//           const feature2 = value[0]?.user[0]?.feature2;
//           const displayFeature = feature1 ? "Feature 1" : feature2 ? "Feature 2" : "None";
//           return <span className="ml-4">{displayFeature}</span>;
//         }
//       },
//       {
//         Header: "Plan Cost",
//         accessor: "planCost",
//         Cell: ({ value }) => <span className="ml-4">{value}</span>,
//       },
//       {
//         Header: "Status",
//         accessor: "is_active",
//         Cell: ({ value }) => (
//           <div className="flex items-center">
//             {value ? (
//               <>
//                 <MdCheckCircle className="text-green-500" />
//                 <span className="ml-2">Active</span>
//               </>
//             ) : (
//               <>
//                 <MdOutlineError className="text-red-500" />
//                 <span className="ml-2">In-Active</span>
//               </>
//             )}
//           </div>
//         ),
//       },
//       {
//         Header: "Created At",
//         accessor: "createdAt",
//         Cell: ({ value }) => format(new Date(value), "yyyy-MM-dd HH:mm:ss"),
//       },
//       {
//         Header: "Actions",
//         accessor: "actions",
//         Cell: ({ row }) => <Progress width="w-[68px]" value={row.original} />,
//       },
//     ],
//     []
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//   } = useTable(
//     {
//       columns,
//       data: tableData,
//       initialState: { pageSize: 100 },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   return (
//     <Card extra="w-full h-full p-4 sm:overflow-x-auto">
//       <div className="flex justify-between items-center">
//         <div className="text-xl font-bold text-navy-700 dark:text-white">
//           {/* Table Header */}
//         </div>
//         <CardMenu getSystemPromptData={getSystemPromptData} />
//       </div>
//       {loading ? (
//         <div className="mb-4 flex items-center justify-center">
//           <svg
//             className="mr-3 h-5 w-5 animate-spin text-gray-600"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//           >
//             <circle
//               className="opacity-25"
//               cx="12"
//               cy="12"
//               r="10"
//               stroke="currentColor"
//               strokeWidth="4"
//             />
//             <path
//               className="opacity-75"
//               fill="currentColor"
//               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.556a4.002 4.002 0 00-4 4H6.002zM20 12c0-3.042-1.135-5.82-3-7.938L16 4.472A8.001 8.001 0 0120 12zm-8 7.938A8.001 8.001 0 014.472 16h3.556a4.002 4.002 0 004 4v3.556zm8-7.938a8.001 8.001 0 01-4.472 7.938v-3.556a4.002 4.002 0 00-4-4h3.556z"
//             />
//           </svg>
//           <p>Loading</p>
//         </div>
//       ) : tableData.length === 0 ? (
//         <div className="mb-4 text-center text-gray-600">No Data</div>
//       ) : (
//         <div className="mt-8 overflow-x-scroll">
//           <table {...getTableProps()} className="w-full min-w-max table-auto">
//             <thead>
//               {headerGroups.map((headerGroup, index) => (
//                 <tr {...headerGroup.getHeaderGroupProps()} key={index}>
//                   {headerGroup.headers.map((column) => (
//                     <th
//                       {...column.getHeaderProps(column.getSortByToggleProps())}
//                       className="border-b border-gray-200 p-4 pb-2 text-start dark:!border-navy-700"
//                     >
//                       <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
//                         {column.render("Header")}
//                       </div>
//                     </th>
//                   ))}
//                 </tr>
//               ))}
//             </thead>
//             <tbody {...getTableBodyProps()}>
//               {page.map((row, index) => {
//                 prepareRow(row);
//                 return (
//                   <tr {...row.getRowProps()} key={index}>
//                     {row.cells.map((cell, index) => {
//                       return (
//                         <td
//                           {...cell.getCellProps()}
//                           key={index}
//                           className="p-2 pt-2 sm:text-[14px]"
//                         >
//                           {cell.render("Cell")}
//                         </td>
//                       );
//                     })}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </Card>
//   );
// };

// export default SubscriptionPlan;
