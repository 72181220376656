import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import ConfigMenu from "components/card/ConfigMenu";
import { ENDPOINT } from "constants/environment";
import Card from "components/card";
import { format } from "date-fns";
import Actions from "components/actions";
import axios from "axios";

const ConfigTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getConfigKeyData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${ENDPOINT}/businessSubscription`);
      const mappedData = data.data?.map((item) => ({
        subscriberName: item.userId,
        subscriptionPlan: item.subscriptionPlanid,
        planCost: item.subscriptionCost,
        dateOfSubscription: item.transactionDate,
        expiryDate: item.exipreDate,
        isActive: item.is_active,
        actions: item.id,
      })) || [];
      setTableData(mappedData);
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfigKeyData();
  }, []);

  const columnsData = useMemo(() => [
    {
      Header: "Subscriber Name",
      accessor: "subscriberName",
    },
    {
      Header: "Subscription Plan",
      accessor: "subscriptionPlan",
    },
    {
      Header: "Subscription Plan Cost",
      accessor: "planCost",
    },
    {
      Header: "Date of Subscription",
      accessor: "dateOfSubscription",
      Cell: ({ cell: { value } }) => format(new Date(value), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
      Cell: ({ cell: { value } }) => format(new Date(value), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: ({ value }) => (
        <div className="flex items-center justify-start">
          {value ? (
            <>
              <MdCheckCircle className="text-green-500" />
              <span className="ml-2">Active</span>
            </>
          ) : (
            <>
              <MdOutlineError className="text-red-500" />
              <span className="ml-2">In-Active</span>
            </>
          )}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => <Actions width="w-[68px]" value={cell.value} cell={cell} />,
    },
  ], []);

  const tableInstance = useTable(
    {
      columns: columnsData,
      data: tableData,
      initialState: { pageSize: 100 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {/* Table Header */}
        </div>
        <ConfigMenu getConfigKeyData={getConfigKeyData} />
      </div>
      <div className="mt-4 h-full overflow-x-scroll xl:overflow-auto">
        {loading ? (
          <div className="mb-4 flex items-center justify-center">
            <svg
              className="mr-3 h-5 w-5 animate-spin text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.556a4.002 4.002 0 00-4 4H6.002zM20 12c0-3.042-1.135-5.82-3-7.938L16 4.472A8.001 8.001 0 0120 12zm-8 7.938A8.001 8.001 0 014.472 16h3.556a4.002 4.002 0 004 4v3.556zm8-7.938a8.001 8.001 0 01-4.472 7.938v-3.556a4.002 4.002 0 004-4h3.556z"
              ></path>
            </svg>
            <p>Loading</p>
          </div>
        ) : (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b border-gray-200 pr-28 pb-[10px] text-center dark:!border-navy-700"
                    >
                      <p className="w-20 text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => (
                      <td
                        className="pt-[14px] pb-[8px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Card>
  );
};

export default ConfigTable;
