import React from "react";

// Admin Imports
// import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
// import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  // MdHome,
  // MdOutlineShoppingCart,
  // MdPerson,
  MdLock,
  MdRecommend,
} from "react-icons/md";
import {  TbFileSettings } from "react-icons/tb";
// import ReccommendedTripTable from "views/recommended";
import { ROUTES } from "constants";
// import { TbFileSettings } from "react-icons/tb";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },

  {
    name: "Subscription plan",
    layout: ROUTES.admin,
    // icon: <MdBarChart  />,
    icon: <TbFileSettings className="h-6 w-6" />,
    path: "subscriptionPlan",
    component: <DataTables />,
  },
  {
    name: "Subscribers",
    layout: ROUTES.admin,
    path: "subscribers",
    // icon: <MdBarChart  />,
    icon: <MdRecommend className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  // {
  //   name: "Recommended Trips",
  //   layout: ROUTES.admin,
  //   path: "recommended_trips",
  //   icon: <MdRecommend className="h-6 w-6" />,
  //   component: <ReccommendedTripTable />,
  //   secondary: true,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign in",
    layout: ROUTES.auth,
    path: "default",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
