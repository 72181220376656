export const ENVIRONMENT = {
  test: {
    ENDPOINT: "http://103.177.43.210:5001/v1",
    // recommendedTripsURL: "http://103.177.43.210:5001/v1",
  },
};

const { ENDPOINT, recommendedTripsURL } = ENVIRONMENT.test;

export { ENDPOINT, recommendedTripsURL };
