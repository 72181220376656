import { createContext, useContext, useState, useMemo } from "react";
import { ENDPOINT } from "constants/environment";
import axios from "axios";

const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const initialFormState = useMemo(
    () => ({
      planName: "",
      planDescription: "",
      planFeatures: "",
      planModulepermission: '',
      planCost: "",
    }),
    []
  );
  const subscriberData = useMemo(
    () => ({
      subscriberName: "",
      subscriptionPlan: "",
      planCost: "",
      dateOfSubscription: "",
      expiryDate: "",
    }),
    []
  );
  // const initialConfigState = useMemo(
  //   () => ({
  //     key: "",
  //     value: "",
  //     status: "",
  //     comment: "",
  //   }),
  //   []
  // );
  const [formData, setFormData] = useState(initialFormState);
  const [iterinaryFormData, setIterinaryFormData] = useState(subscriberData);
  // const [configData, setConfigData] = useState(initialConfigState);
  // const getConfigkeydata = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api-test.zenvoya.ai/app/config"
  //     );
  //     if (response.data && response.data.data) {
  //       setTableData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const [tableData, setTableData] = useState([]);
  const [iterinaryList, setIterinaryList] = useState([]);

  const getSystemPromptData = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/subscriptionPlan`);
      if (response.data && response.data.data) {
        setTableData(response.data.data);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const value = {
    userDetails,
    setUserDetails,
    formData,
    setFormData,
    initialFormState,
    tableData,
    getSystemPromptData,
    // getConfigkeydata,
    // initialConfigState,
    setIterinaryList,
    iterinaryList,
    iterinaryFormData,
    setIterinaryFormData,
    subscriberData,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
