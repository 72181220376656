import React from "react";
// import "ckeditor5.css";
import { useGlobalContext } from "context/globalContext";
import { updateIterinaryAPI } from "Api/iterinaryListApi";
import toast from "react-hot-toast";

const ConfigForm = ({ isOpen, onClose, setFetchIterinaryList }) => {
  //   const { getSystemPromptData } = useGlobalContext();
  const {
    setIterinaryFormData,
    iterinaryFormData,
    initialIterinaryFormData,
    userDetails,
  } = useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIterinaryFormData({
      ...iterinaryFormData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(iterinaryFormData, "iterinaryFormdata");
    // setIsSubmitting(true);
    if (!userDetails?.id) return;
    const {
      id: iterinaryID,
      tripName,
      featured,
      topDestination,
      numberOfDaysSelected,
    } = iterinaryFormData || {};
    if (!iterinaryID) return;
    const response = await updateIterinaryAPI(
      {
        id: iterinaryID,
        tripName: tripName,
        userId: userDetails.id,
        featured,
        topDestination,
        numberOfDaysSelected,
      }
      // setIsSubmitting
    );
    if (response) {
      toast.success("Iterinary updated successfully!");
      setFetchIterinaryList(true);
      onClose();
    }
  };

  const handleCancel = () => {
    setIterinaryFormData(initialIterinaryFormData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="z-50 mx-auto h-4/5 w-full max-w-md overflow-auto rounded-xl bg-white p-6 shadow-xl">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Subscriber Name:
            </label>
            <input
              type="text"
              disabled
              name="subscriberName"
              value={iterinaryFormData.subscriberName}
              onChange={handleChange}
              className="w-full rounded-md  px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Subscription Plan
            </label>
            <input
              type="text"
              disabled
              name="subscriptionPlan"
              value={iterinaryFormData.subscriptionPlan}
              onChange={handleChange}
              className="w-full rounded-md  px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Cost
            </label>
            <input
              type="text"
              disabled
              name="planCost"
              value={iterinaryFormData.planCost}
              onChange={handleChange}
              className="w-full rounded-md  px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Date of Subscription
            </label>
            <input
              type="text"
              disabled
              name="dateOfSubscription"
              value={iterinaryFormData.dateOfSubscription}
              onChange={handleChange}
              className="w-full rounded-md  px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Expiry Date
            </label>
            <input
              type="text"
              disabled
              name="expiryDate"
              value={iterinaryFormData.expiryDate}
              onChange={handleChange}
              className="w-full rounded-md  px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">Status</label>
            <input
              type="text"
              disabled
              name="isActive"
              value={iterinaryFormData.isActive ? "Active" : "In-Active"}
              onChange={handleChange}
              className="w-full rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigForm;
