import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useGlobalContext } from "context/globalContext";
import { ENDPOINT } from "constants/environment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  SourceEditing,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  Alignment,
  Link,
  MediaEmbed,
  BlockQuote,
  CodeBlock,
  List,
  Table,
} from "ckeditor5";

const API_URL = `${ENDPOINT}/subscriptionPlan`;

const FormPopup = ({
  isOpen,
  onClose,
  onSubmit,
  isEditMode,
  initialFormData,
}) => {
  const { formData, setFormData, initialFormState } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getSystemPromptData } = useGlobalContext();

  useEffect(() => {
    if (isEditMode && initialFormData) {
      setFormData(initialFormData);
    } else {
      setFormData(initialFormState);
    }
  }, [isEditMode, initialFormData, initialFormState, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value,'data')
    setFormData({
      ...formData,
      [name]: value,
    
    });

  };

  const   handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      planFeatures: data,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = {
        ...formData,
        is_active: formData.status === "active",
        "planModulepermission": [
                {
                    "user": [
                        {
                            "feature1": formData.planModulepermission === "feature1",
                            "feature2":  formData.planModulepermission === "feature2"
                        }
                    ],
                    "Emails": true
                }
            ],
         
       
      };
      

      const response = formData.id
        ? await axios.put(`${API_URL}/${formData.id}`, payload)
        : await axios.post(API_URL, payload);

      const successMessage = formData.id
        ? "Subscription plan has been updated"
        : "Subscription plan has been saved";
      toast.success(successMessage);

      onSubmit(response.data);
      onClose();
      setFormData(initialFormState);
      getSystemPromptData();
    } catch (error) {
      toast.error("Error creating/updating Plan. Please try again.");
      // console.error("Error creating/updating Plan:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormState);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="z-50 mx-auto h-4/5 w-full max-w-md overflow-auto rounded-xl bg-white p-6 shadow-xl">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Name:
            </label>
            <input
              type="text"
              name="planName"
              value={formData.planName}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Description:
            </label>
            <textarea
              name="planDescription"
              value={formData.planDescription}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Features:
            </label>
            {/* <input
              type="text"
              name="planFeatures"
              value={formData.planFeatures}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            /> */}
            <CKEditor
                editor={ClassicEditor}
                data={formData.planFeatures}
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "|",
                      "sourceEditing",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "subscript",
                      "superscript",
                      "|",
                      "alignment:left",
                      "alignment:center",
                      "alignment:right",
                      "alignment:justify",
                      "|",
                      "link",
                      "insertTable",
                      "mediaEmbed",
                      "blockQuote",
                      "codeBlock",
                      "|",
                      "bulletedList",
                      "numberedList",
                    ],
                  },
                  plugins: [
                    Bold,
                    Essentials,
                    Italic,
                    Mention,
                    Paragraph,
                    Undo,
                    SourceEditing,
                    Underline,
                    Strikethrough,
                    Subscript,
                    Superscript,
                    Alignment,
                    Link,
                    MediaEmbed,
                    BlockQuote,
                    CodeBlock,
                    List,
                    Table,
                  ],
                  // licenseKey: '<YOUR_LICENSE_KEY>',
                  // mention: {
                  //   // Mention configuration
                  // },
                  // initialData: "<p>Hello from CKEditor 5 in React!</p>",
                }}
                onChange={handleEditorChange}
              />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Module Permission:
            </label>
            <div className="flex items-center">
              {["feature1", "feature2"].map((feature) => (
                <label key={feature} className="mr-4 text-pink-500">
                  <input
                    type="radio"
                    name="planModulepermission"
                    value={feature}
                    checked={formData.planModulepermission === feature}
                    onChange={handleChange}
                    className="relative top-0.5 mr-1 cursor-pointer"
                  />
                  {feature.charAt(0).toUpperCase() + feature.slice(1)}
                </label>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Plan Cost:
            </label>
            <input
              name="planCost"
              type="number"
              value={formData.planCost}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Status:
            </label>
            <div className="flex items-center">
              <label className="mr-4 text-pink-500">
                <input
                  type="radio"
                  name="status"
                  value="active"
                  checked={formData.status === "active"}
                  onChange={handleChange}
                  className="relative top-0.5 mr-1 cursor-pointer"
                />
                Active
              </label>
              <label className="text-pink-500">
                <input
                  type="radio"
                  name="status"
                  value="inactive"
                  checked={formData.status === "inactive"}
                  onChange={handleChange}
                  className="relative top-0.5 mr-1 cursor-pointer"
                />
                In-Active
              </label>
            </div>
          </div>
          <div className="mt-4 flex justify-between">
            <button
              type="submit"
              className={`rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                isSubmitting ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={isSubmitting}
            >
              {isEditMode ? "Update" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormPopup;
